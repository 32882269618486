@use '@angular/material' as mat;
@import './custom-theme-colors';
@include mat.core();

// Dark Theme
$dsw-mobile-homeoffice-thesource-web-primary--dark: mat.m2-define-palette($homeoffice-white);
$dsw-mobile-homeoffice-thesource-web-accent--dark: mat.m2-define-palette($homeoffice-red);

// Light Theme
$dsw-mobile-homeoffice-thesource-web-primary--light: mat.m2-define-palette($homeoffice-white);
$dsw-mobile-homeoffice-thesource-web-accent--light: mat.m2-define-palette($homeoffice-white);

// The warn palette is optional (defaults to red).
$dsw-mobile-homeoffice-thesource-web-warn: mat.m2-define-palette($homeoffice-red);

$dsw-mobile-homeoffice-thesource-web-background: mat.m2-define-palette($homeoffice-red);

// Create the theme object (a Sass map containing all of the palettes).
$dsw-mobile-homeoffice-thesource-web-theme--dark: mat.m2-define-dark-theme((
        color: (
          theme-type: dark,
          primary: $dsw-mobile-homeoffice-thesource-web-primary--dark,
          accent: $dsw-mobile-homeoffice-thesource-web-accent--dark,
          warn: $dsw-mobile-homeoffice-thesource-web-warn
       )
));



// Create the theme object (a Sass map containing all of the palettes).
$dsw-mobile-homeoffice-thesource-web-theme--light: mat.m2-define-light-theme((
        color: (
          primary: $dsw-mobile-homeoffice-thesource-web-primary--light,
          accent: $dsw-mobile-homeoffice-thesource-web-accent--light,
          warn: $dsw-mobile-homeoffice-thesource-web-warn
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.theme--dark {
  @include mat.all-component-themes($dsw-mobile-homeoffice-thesource-web-theme--dark);
}

.theme--light {
  @include mat.all-component-themes($dsw-mobile-homeoffice-thesource-web-theme--light);
}
