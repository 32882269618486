app-modal,
app-store-list-modal {
  display: none;
}

.modal__dialog__actions__action {
  display: block;
  padding: 1.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: lighten(rgb(213, 6, 66), 10%); // replace
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-top: 1px solid;
  border-image: linear-gradient(45deg, rgb(242, 242, 242), rgb(229, 229, 229)) 1;
}
