/* ========================================================================
     Global: Base Configuration
 ========================================================================== */

// Colors
// -------------------------

// Primary Colors
$brand--dark: rgb(0, 0, 0); // black
$brand--primary: rgb(213, 6, 66); // red
$brand--light: rgb(255, 255, 255); // white

// Neutral Colors
$brand__gray: rgb(229, 229, 229);
$brand__gray--dark: rgb(178, 178, 178);
$brand__gray--light: rgb(242, 242, 242);

// Alerts
$brand__alert--highPriority: rgb(208, 2, 27);
$brand__alert--lowPriority: $brand--light;

// Reference
$brand__reference--icon: rgb(255, 180, 129);

// Department Colors
$department__mktg: rgb(102, 191, 236);
$department__hr: rgb(126, 211, 33);
// TODO: Validate color
$department__lp: rgb(144, 19, 254);
// TODO: Validate color
$department__merch: rgb(255, 75, 149);
$department__ops: rgb(255, 121, 0);

// PDF Viewer
$pdf-viewer__highlight: rgb(255, 255, 0);

// Scaffolding
// -------------------------

$body__bg-color: $brand--light;
$text--color: $brand--dark;

// Links
// -------------------------

$link__color: $brand--primary;
$link__hover__color: darken($link__color, 15%);
$link__hover__decoration: underline;

// Typography
// -------------------------

$font__path: './assets/fonts';
$font__paragraph: 'Roboto';
$font__headings: 'P22JohnstonUnderground';
$font-family__paragraph: $font__paragraph, Helvetica, Arial, sans-serif;
$font-family__headings: $font__headings, Helvetica, Arial, sans-serif;

$font-weight--light: 200;
$font-weight--regular: 400;
$font-weight--medium: 600;
$font-weight--bold: 800;

$font-size__base-percent: 62.5%;
$font-size__base: 1.4rem; // ~10px = 16px(default) * 0.625 */
$font-size__h1: 2.4rem; // ~24px
$font-size__h2: 2rem; // ~20px
$font-size__h3: 1.8rem; // ~18px
$font-size__h4: 1.6rem; // ~16px
$font-size__nav-title: 1.5rem;

$line-height--base: 2.2rem; // ~22px
$letter-spacing: 0.1rem;

// layout sizes
$header--height--base: 44;
$header--height: (44 * 1px);
$footer--height--base: 62;
$footer--height: (62 * 1px);

$body--height--base: ($header--height--base + $footer--height--base);
$body--height: calc(100vh - (#{$body--height--base} * 1px));
$body--height__ipad: calc(var(--app-height) - (#{$body--height--base} * 1px));
