@import '../config';
@import '../mixins/cards';
@import '../mixins/buttons';

$day-number__circle-size: 12px;

.fc {
  .fc-widget-header {
    border: 0;
    text-align: left;
    font-size: 1.1rem;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: $brand__gray--dark;
    font-weight: 200;
    padding-bottom: 0;
  }

  // hider border on first row
  .fc-body > tr:first-child > td.fc-widget-content {
    border-top: 0;
  }

  // hider border on last row
  .fc-body > tr:last-child > td.fc-widget-content {
    border-bottom: 0;
  }

  td {
    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    // today's date on the calendar
    &.fc-today {
      background: $brand--light;
    }
  }
}

// date number in top left corner
.fc-ltr .fc-dayGrid-view .fc-day-top {
  .fc-day-number {
    float: left;
    padding: 2px;
    margin-left: 5px;
    margin-top: 2px;
    font-size: 0.9rem;
    font-weight: $font-weight--bold;
    color: $brand--dark;
  }

  &.fc-today .fc-day-number {
    background-color: $brand--dark;
    color: $brand--light;
    border-radius: 50%;
    border: 2px solid $brand--dark;
    text-align: center;
    width: $day-number__circle-size;
    height: $day-number__circle-size;
    line-height: $day-number__circle-size;
    vertical-align: middle;
  }
}

// !important needed to override the inline style automatically added to this element
.fc-scroller {
  overflow-y: auto !important;
}

// height of each row
.fc-dayGrid-view .fc-body .fc-row {
  min-height: 10rem;

  // scrollbar fix for lower resolution device
  @media screen and (max-height: 694px) {
    min-height: 9rem;
  }
}

// borders
.fc-day-grid-event,
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left-width: 3px;
  font-size: 1rem;
  padding: 1px 5px;
  margin: 0 0 1px 0;
}

.fc tr:first-child > td > .fc-day-grid-event {
  margin-top: 0;
}

// + 2 more items... styling
.fc a.fc-more {
  font-size: 1rem;
  color: $brand__gray--dark;

  &:after {
    content: ' items...';
  }
}

// custom class added to week view daily task items
.fc-daily-task {
  box-shadow: 0 0 4px #ccc;
  padding-top: 5px;

  .fc-content {
    @include card__item;
    padding: 1rem;
    font-size: 12px;
    border-bottom: 0;
    white-space: normal;

    .fc-title {
      overflow-wrap: break-word;
      width: 80%;
    }

    span {
      line-height: 1.5rem;
      display: inline-flex;
    }
  }

  &__todo {
    @include circle__button;
  }
}

.fc-calendar-event {
  padding-top: 5px;

  .fc-content {
    padding: 1rem;
    font-size: 12px;
  }

  span {
    line-height: 1;
    white-space: normal;
  }
}
