/* ========================================================================
     Global: typography
 ========================================================================== */

// Font Face Definitions
// -------------------------

// P22 Johnston Underground - Regular
@font-face {
  font-family: $font__headings;
  //@include fontdef('./assets/fonts', $font__headings);
  src: url('../assets/fonts/P22JohnstonUnderground-Regular.ttf');
  font-weight: $font-weight--regular;
  font-style: normal;
  font-display: swap;
}

// P22 Johnston Underground - Bold
@font-face {
  font-family: $font__headings;
  src: url('../assets/fonts/P22JohnstonUnderground-Bold.ttf');
  font-weight: $font-weight--bold;
  font-style: normal;
  font-display: swap;
}

// Roboto - Light
@font-face {
  font-family: $font__paragraph;
  src: url('../assets/fonts/Roboto-Light.ttf');
  font-weight: $font-weight--light;
  font-style: normal;
  font-display: swap;
}

// Roboto - Regular
@font-face {
  font-family: $font__paragraph;
  src: url('../assets/fonts/Roboto-Regular.ttf');
  font-weight: $font-weight--regular;
  font-style: normal;
  font-display: swap;
}

// Roboto - Medium
@font-face {
  font-family: $font__paragraph;
  src: url('../assets/fonts/Roboto-Medium.ttf');
  font-weight: $font-weight--medium;
  font-style: normal;
  font-display: swap;
}

// Roboto - Bold
@font-face {
  font-family: $font__paragraph;
  src: url('../assets/fonts/Roboto-Bold.ttf');
  font-weight: $font-weight--bold;
  font-style: normal;
  font-display: swap;
}

// Material Icons
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/Material-Icons.woff2) format('woff2');
}

// Layout
// -------------------------

html {
  font-size: $font-size__base-percent;
}

body {
  font-family: $font-family__paragraph;
  font-size: $font-size__base;
  line-height: $line-height--base;
  letter-spacing: $letter-spacing;
}

// Headings
// -------------------------

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: $font-family__headings;
  font-weight: $font-weight--medium;
}

h1,
.h1 {
  font-size: $font-size__h1;
}

h2,
.h2 {
  font-size: $font-size__h2;
}

h3,
.h3 {
  font-size: $font-size__h3;
}

h4,
.h4 {
  font-size: $font-size__h4;
}

p,
li,
td {
  font-weight: $font-weight--light;
  font-size: $font-size__base;
  line-height: $line-height--base;
  letter-spacing: 0.4px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
