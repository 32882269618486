@import '../config';

.pdfViewer {
  div.textLayer {
    opacity: 0.4;

    span.highlight,
    span.highlight.selected {
      background-color: $pdf-viewer__highlight;
    }
  }
}
