.icon-alerts-dims {
	width: 22px;
	height: 24px;
}

.icon-alerts-calendar-dims {
	width: 30px;
	height: 30px;
}

.icon-alerts-document-dims {
	width: 30px;
	height: 30px;
}

.icon-alerts-need-to-know-dims {
	width: 30px;
	height: 30px;
}

.icon-alerts-task-dims {
	width: 30px;
	height: 30px;
}

.icon-alerts-urgent-dims {
	width: 30px;
	height: 30px;
}

.icon-check-circle-dims {
	width: 24px;
	height: 24px;
}

.icon-chevron-small-left-dims {
	width: 24px;
	height: 24px;
}

.icon-chevron-small-left-black-dims {
	width: 10px;
	height: 18px;
}

.icon-chevron-small-right-dims {
	width: 24px;
	height: 24px;
}

.icon-chevron-small-right-black-dims {
	width: 10px;
	height: 18px;
}

.icon-chevron-small-right-currentColor-dims {
	width: 30px;
	height: 30px;
}

.icon-clock-dims {
	width: 18px;
	height: 18px;
}

.icon-close-light-dims {
	width: 24px;
	height: 24px;
}

.icon-file-icon-document-dims {
	width: 30px;
	height: 40px;
}

.icon-file-icon-excel-dims {
	width: 30px;
	height: 40px;
}

.icon-file-icon-image-dims {
	width: 30px;
	height: 40px;
}

.icon-file-icon-pdf-dims {
	width: 30px;
	height: 40px;
}

.icon-file-icon-powerpoint-dims {
	width: 30px;
	height: 40px;
}

.icon-file-icon-video-dims {
	width: 30px;
	height: 40px;
}

.icon-file-icon-word-dims {
	width: 30px;
	height: 40px;
}

.icon-folder-outline-dims {
	width: 40px;
	height: 40px;
}

.icon-image-dims {
	width: 30px;
	height: 30px;
}

.icon-menu-down-outline-dims {
	width: 24px;
	height: 24px;
}

.icon-menu-right-outline-dims {
	width: 24px;
	height: 24px;
}

.icon-open-in-browser-dims {
	width: 24px;
	height: 24px;
}

.icon-printer-dims {
	width: 24px;
	height: 24px;
}

.icon-profile-dims {
	width: 22px;
	height: 22px;
}

.icon-refresh-dims {
	width: 24px;
	height: 24px;
}

.icon-remote-file-dims {
	width: 32px;
	height: 32px;
}

.icon-search-dims {
	width: 22px;
	height: 22px;
}

.icon-tab-calendar-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-calendar-selected-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-daily-tasks-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-daily-tasks-selected-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-documents-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-documents-selected-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-home-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-home-selected-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-need-to-know-dims {
	width: 40px;
	height: 32.5px;
}

.icon-tab-need-to-know-selected-dims {
	width: 40px;
	height: 32.5px;
}

.icon-video-dims {
	width: 30px;
	height: 30px;
}

