@import '../config';

$event-border-radius: 20px;

@mixin event-end {
  border-top-right-radius: $event-border-radius;
  border-bottom-right-radius: $event-border-radius;
}

@mixin event-start {
  border-top-left-radius: $event-border-radius;
  border-bottom-left-radius: $event-border-radius;
}

.fc {
  &__retail-year {
    display: flex;

    &__quarter {
      border-right: solid 1px $brand__gray;
      width: 25%;
      padding: 0 2rem;

      &:last-child {
        border-right: none;
      }

      &--title {
        color: $brand__gray--dark;
        text-align: center;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }
    }

    &__month {
      cursor: pointer;

      &--title {
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        text-transform: uppercase;
      }
      table {
        margin-bottom: 1.5rem;

        td {
          border-style: none;
          text-align: center;
        }

        thead {
          color: $brand__gray--dark;
        }

        tr > td.fc__retail-year__event:last-child {
          @include event-end;
        }

        tr > td.fc__retail-year__event:first-child {
          @include event-start;
        }
      }
    }

    &--today {
      background-color: $brand--dark;
      color: $brand--light;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
      font-weight: $font-weight--bold;
    }

    &__event {
      background-color: $brand__gray--light;
      color: $brand--dark;

      &.fc__retail-year--today {
        border-radius: 0;
      }
    }

    &__event--start {
      @include event-start;
      &.fc__retail-year--today {
        @include event-start;
      }
    }

    &__event--end {
      @include event-end;
      &.fc__retail-year--today {
        @include event-end;
      }
    }
  }
}

@media print {
  .fc__retail-year {
    height: 100% !important;
  }
}
