@import '../config';

$box-shadow: 0 0px 5px #999;
$box-shadow--bottom: 0 2px 10px -2px #999;
$item--border-color: rgba(238, 238, 238, 0.7);
$z-index: 101;

@mixin card__list {
  height: 100%;
  background-color: $brand--light;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: $z-index;
}

@mixin card__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem 2rem 0;
  border-bottom: 1px solid $item--border-color;
  background-color: $brand--light;
  cursor: pointer;
}

@mixin card__item--new {
  border-left-width: 20px;
  border-left-style: solid;

  &__border {
    font-size: 1rem;
    transform: rotate(270deg);
    position: absolute;
    left: 0;
    margin-left: -22px;
    color: $brand--light;
  }
}

@mixin card__item--selected {
  border-left-width: 4px;
  border-left-style: solid;
  box-shadow: $box-shadow--bottom;
  z-index: 1;
}

@mixin card__item__info {
  padding-left: 1rem;
  width: 180px;

  &__department,
  &__featured {
    font-size: 1rem;
  }

  &__title {
    font-size: 1.3rem;
  }
}
