$circle-button__size: 24px;

@mixin link__button__base {
  display: inline-block;
  padding: 1rem 2rem;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-family__paragraph;
  font-weight: $font-weight--light;
  font-size: $font-size__base;
}

@mixin link__button--black {
  @include link__button__base;
  background-color: $brand--dark;
  color: $brand--light;

  &:hover {
    background-color: lighten($brand--dark, 15%);
  }
}

@mixin circle__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $circle-button__size;
  min-width: $circle-button__size;
  height: $circle-button__size;
  border-radius: 50%;
  border: 1px solid $brand--dark;
  line-height: 1;
  padding: 0;
  margin: 0;
}
