/* ========================================================================
     Global: Layout
 ========================================================================== */

@import './mixins/_layout';

:root {
  --app-height: 100%;
  --app-bannerTxt--color: #ffffff;
  --app-bannerTxt--shadow: #000000;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100vh;
}

body {
  &.theme--light {
    background-color: #efefef;

    @media print {
      background: $brand--light;
    }
  }

  &.theme--dark {
    background-color: $brand--dark;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

p>a {
  color: $brand--primary;
  font-weight: 400;
}


svg {
  font-size: 0;
  line-height: 0;
}

app-svg-icon {
  margin: 0;
  padding: 0;
  line-height: 0;
  will-change: transform;
}

app-reference ul,
app-reference li {
  list-style: none;
  padding: 0;
  margin: 0;
}

input {
  -webkit-appearance: none;
}

app-reference,
app-home,
app-daily-tasks,
app-need-to-know,
app-calendar {
  width: 100%;
}

app-search-box {
  display: flex;
  flex-grow: 1;
}
