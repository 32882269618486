// override badge position for alerts
.mat-badge.mat-badge-above.mat-badge-before {
  top: 5px;
  left: 1px;
  z-index: 10;
}

.mat-select-panel {
  max-height: 300px;

  .mat-option {
    line-height: 2rem;
    height: 2rem;
  }
}

.mat-option-text {
  text-transform: uppercase;
}

.mat-form-field {
  font-size: 12px;
}

.theme--dark .mat-form-field-wrapper {
  min-width: 300px;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.theme--light {
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: black;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    background-color: #dadadaa8;
  }

  .mat-select-arrow-wrapper {
    padding-top: 0.7rem;
  }

  .mat-select-value,
  .mat-select-arrow,
  .mat-form-field-appearance-outline.mat-focused.mat-primary .mat-select-arrow {
    color: #000;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand__gray--light;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.25rem 0 1.25rem 0;
    border-top: 0.5rem solid transparent;
  }

  .mat-form-field-wrapper {
    min-width: 240px;
  }

  .mat-calendar-body-selected {
    background-color: $brand--dark;
    color: $brand--light;
  }
}

  // mat-toggle buttons on calendar component only
.theme--light app-calendar {
  .mat-button-toggle-group-appearance-standard {
    border-color: $brand--dark;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 24px;
    padding: 0 12px;
    font-size: 1rem;
  }

  .mat-button-toggle-checked {
    background-color: $brand--dark;

    .mat-button-toggle-label-content {
      color: $brand--light;
    }
  }

  .mat-form-field-wrapper {
    min-width: 150px;
  }
}
